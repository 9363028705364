import { useEffect } from "react";
import SuccessMessageForm from "./SuccessMessageForm";
import Careers from "./components/Careers";
import ContactUs from "./components/ContactUs";
import CookiePolicy from "./components/CookiePolicy";
import HomeScreen from "./components/HomeView/HomeScreen";
import MeetOurTeam from "./components/MeetOurTeam";
import PrivacyPolicy from "./components/PrivacyPolicy";
import TermsAndConditions from "./components/TermsAndConditions";
import ViewOurWorks from "./components/ViewOurWorks";
import What_We_Do from "./components/WhatWeDo/What_We_Do";
import AboutUs from "./components/about/AboutUs";
import { HashRouter, Routes, Route } from "react-router-dom";

function App() {
  
  return (
  <HashRouter>
    <Routes>
      <Route path="/"  element={<HomeScreen />} ></Route>
      <Route path="/about-theja.in" element={<AboutUs />} ></Route>
      <Route path="/our-team" element={<MeetOurTeam />} ></Route>
      <Route path="/What-we-do" element={<What_We_Do />} ></Route>
      <Route path="/contact-theja.in" element={<ContactUs />} ></Route>
      <Route path="/form-submitted" element={<SuccessMessageForm />}></Route>

      <Route path="/privacy-policy" element={<PrivacyPolicy />} ></Route>
      <Route path="/cookie-policy" element={<CookiePolicy />} ></Route>
      <Route path="/terms-conditions" element={<TermsAndConditions />} ></Route>
      <Route path="/careers" element={<Careers />} ></Route>
      <Route path="/our-works" element={<ViewOurWorks />} ></Route>
    </Routes>
    
  </HashRouter>
  );
}

export default App;

