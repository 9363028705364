import React from "react";
import {
  bg_color_green,
  bottomLeftNoneRounded,
  bottomRightNoneRounded,
  textContent,
  titleContent,
  topRightNoneRounded,
} from "../../Style";
import { RightArrowIcon } from "../../buttons/Buttons";

export default function OurService() {
  return (
    <div
      className={`${bg_color_green} text-white w-[100%] h-fit p-2 py-14 pb-8 flex flex-row flex-wrap ipadMin:justify-center ipadMax:justify-center mobile:justify-center gap-5 items-center`}
    >
    
      <div className="h-fit flex  flex-col items-center  gap-5 w-[45%] justify-center">
        <div className="flex flex-row flex-wrap w-[80%] leading-10 mobile:w-full  justify-center items-center  gap-5">
          <img
            className="h-16"
            src={require("../../assets/discover_logo.png")}
            alt=""
            srcSet=""
          />
          <div className={`${titleContent} w-fit `}>Discover</div>
          <div className={`${titleContent} w-fit`}> <span className="font-[200]">our</span> Services</div>
        </div>
        <div className={`${textContent} w-fit flex flex-col pt-14 `}>
          <span className="border-b py-2">UI/UX Designing</span>
          <span className="border-b py-2">Cloud Services</span>
          <span className="border-b py-2">Web Development</span>
          <span className="border-b py-2">Software Consultancy</span>
        </div>
      </div>

      <div className="w-[50%]  mobile:w-full  h-[100%] flex flex-col gap-4 text-green-900 flex-wrap justify-center items-center">
        <a target="_blank"href="http://localsetu.com/"
          className={`border-[22px] ml-14 mobile:ml-0 border-[#FBE204] hover:scale-[1.05] cursor-pointer border-opacity-[50%] ${topRightNoneRounded}  h-[180px] min-w-[300px] mobile:w-[80%] w-fit`}
        >
          <div className="bg-white w-[101%] h-[102%] p-2 mt-[-1px] flex flex-col justify-center items-center  rounded-tl-[40px] rounded-br-[40px]  rounded-tr-none rounded-bl-[40px]">
            <span className={`${textContent} w-[80%] text-center`}>Local Setu</span>
            <span className="w-full flex justify-end items-end">
              <RightArrowIcon />
            </span>
          </div>
        </a>

     <div className="flex flex-row justify-center items-center w-full gap-4 flex-wrap">
     <a target="_blank"  href="https://iot.theja.in/"
          className={`border-[22px] border-[#FBE204]  hover:scale-[1.05] cursor-pointer border-opacity-[50%]  ${bottomLeftNoneRounded}  h-[180px] min-w-[240px] w-fit mobile:w-[80%]`}
        >
          <div className="bg-white w-[101%] h-[101%] p-2 mt-[-1px] flex flex-col justify-center items-center  rounded-tl-[40px] rounded-br-[40px]  rounded-tr-[40px] rounded-bl-none">
            <span className={`${textContent} w-[80%]  text-center`}>IOT services/Products</span>
            <span className="w-full p-1 flex justify-end float-right">
              <RightArrowIcon />
            </span>
          </div>
        </a>
        <a target="_blank"  href="http://nischai.com/"
          className={` border-[22px] border-[#FBE204]  hover:scale-[1.05] cursor-pointer border-opacity-[50%] ${bottomRightNoneRounded}  h-[180px] min-w-[210px] w-fit mobile:w-[80%]`}
        >
          <div className="bg-white w-[101%] h-[102%] mt-[-1px] p-2 flex flex-col justify-center items-center rounded-tl-[40px] rounded-br-none  rounded-tr-[40px] rounded-bl-[40px]">
            <div className="w-full grid place-items-center"><div className=" nischay_logo w-[80px] h-[80px]  rounded-full "></div></div>
            <span className="w-full flex justify-end float-right ">
              <RightArrowIcon />
            </span>
          </div>
        </a>
     </div>
      </div>
      <div className='relative  mobile:hidden left-[-72px] top-[-140px] w-full flex flex-col h-full justify-center items-center'>
      <div className='absolute left-[-20px] top-[-200px] border-2 border-[#FBE204] border-opacity-[40%]  rounded-full w-[200px] h-[200px]'></div>
  <div className='absolute left-5   border-[24px] border-[#FBE204] border-opacity-[40%]  rounded-full w-[200px] h-[200px]'>
  
  </div>
 </div>
      <div className="relative mobile:hidden right-[-72px]  bottom-20 w-full  flex flex-col h-full justify-center items-center">
        <div className="absolute mt-60 right-5 border-2 border-[#FBE204] border-opacity-[40%]  rounded-full w-[200px] h-[200px]"></div>
      </div>
    </div>
  );
}
