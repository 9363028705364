import { useEffect, useState } from "react";
import { bg_color_green } from "../../Style";
import { useNavigate } from "react-router-dom";
export const Header = () => {
  const [ismobileMenu, setMenu] = useState(false);
  const navigate = useNavigate();
  const [isActive, setActive] = useState(
    localStorage.getItem("active") ? localStorage.getItem("active") : "home"
  );
 
  useEffect(()=>{
   setActive(localStorage.getItem('active'))
  },[localStorage.getItem('active')])
  const homeHandler = () => {
    navigate("/");
    localStorage.setItem("active", "home");
  };
  const aboutHandler = () => {
    navigate("/about-theja.in");
    localStorage.setItem("active", "about");
  };

  const contactHandler = () => {
    navigate("/contact-theja.in");
    localStorage.setItem("active", "contactus");
  };
  return (
    <div className={`${bg_color_green} w-full relative  h-fit `}>
      <div
        className={` w-full text-white bg-[linear-gradient(80.84deg,_#869108_30%,_#5D9711_70%)]
       h-[10vh] flex flex-row items-center px-4`}
      >
        <div
          onClick={homeHandler}
          className="w-fit h-full cursor-pointer hover:text-gray-300 flex flex-row items-center justify-center  text-[60px]  font-bold text-[#ffffff] p-1"
        >
          <img
            className="w-[90%] h-[90%]"
            src={require("../../assets/main_logo.png")}
            alt=""
          />{" "}
          <span className="ml-[-10px] text-[3rem] rounded-full ">.</span>{" "}
        </div>
        <div className="w-[100%] h-fit flex flex-row justify-end">
          <div className="w-fit flex flex-row mobile:hidden ipadMin:hidden  largeScreen:text-[1.1rem] laptop:text-[1rem] gap-8 ipadMax:text-[1rem] mobile:text-[0.8rem] uppercase">
            <span
              className={`cursor-pointer hover:scale-[1.1] hover:font-bold ${
                isActive === "home" && "underline "
              }  text-[#fff]`}
              onClick={homeHandler}
            >
              home
            </span>
            <span
              className={`cursor-pointer hover:scale-[1.1]  hover:font-bold ${
                isActive === "about" && "underline"
              }  text-[#fff]`}
              onClick={aboutHandler}
            >
              about us
            </span>
            <a
              target="_blank"
              href="https://iot.theja.in/"
              className={`cursor-pointer hover:scale-[1.1] hover:font-bold ${
                isActive === "iot" && "underline"
              }  text-[#fff]`}
            >
              iot
            </a>
            <a
              target="_blank"
              href="http://nischai.com/"
              className={`cursor-pointer hover:scale-[1.1] hover:font-bold ${
                isActive === "nischai" && "underline"
              }  text-[#fff]`}
            >
              nischai
            </a>
            <a
              target="_blank"
              href="http://localsetu.com/"
              className={`cursor-pointer hover:scale-[1.1] hover:font-bold ${
                isActive === "localsetu" && "underline"
              } text-[#fff]`}
            >
              Localsetu
            </a>
            <span
              className={`cursor-pointer hover:scale-[1.1] hover:font-bold ${
                isActive === "contactus" && "underline"
              }  text-[#fffff]`}
              onClick={contactHandler}
            >
              contact us
            </span>
          </div>
          {ismobileMenu ? (
            <button
              type="button"
              onClick={() => setMenu(false)}
              className=" rounded-ipadMin  cursor-pointer ipadMin:inline-flex hidden mobile:inline-flex text-gray-400 hover:text-gray-500  focus:outline-none"
            >
              <span className="sr-only">Close menu</span>
              <svg
                className="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          ) : (
            <button
              onClick={() => setMenu(true)}
              type="button"
              className="w-auto h-auto cursor-pointer ipadMin:inline-flex hidden mobile:inline-flex"
            >
              <svg
                className="w-5 h-5 cursor-pointer hover:font-bold"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 17 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M1 1h15M1 7h15M1 13h15"
                />
              </svg>
            </button>
          )}
        </div>
        {ismobileMenu && (
          <div
            className="absolute z-auto right-0 h-fit hidden mobile:flex ipadMin:flex flex-row justify-end top-[10vh]"
            id="navbar-default"
          >
            <ul className="bg-black relative  z-auto px-4 p-2 flex flex-col  items-left uppercase gap-2">
              <li
                className="cursor-pointer relative  min-w-[150px] max-w-[300px]  py-2 px-3 text-white hover:text-red-500 focus:text-red-500 hover:underline rounded bg-transparent  hover:font-bold ipadMin:p-0 "
                aria-current="page"
                onClick={() => navigate("/")}
              >
                Home
              </li>
              <li
                className="cursor-pointer relative min-w-[150px] max-w-[300px]  py-2 px-3 text-white rounded bg-transparent focus:text-red-500 hover:text-red-500 hover:underline hover:font-bold"
                aria-current="page"
                onClick={() => navigate("/about-theja.in")}
              >
                About Us
              </li>
              <li className="cursor-pointer relative  min-w-[150px] max-w-[300px]  py-2 px-3 text-white hover:text-red-500 focus:text-red-500 hover:underline rounded bg-transparent  hover:font-bold ipadMin:p-0 ">
                {" "}
                <a
                  target="_blank"
                  href="https://iot.theja.in/"
                  className={`cursor-pointer hover:scale-[1.1] hover:font-bold ${
                    isActive === "iot" && "underline"
                  } largeScreen:px-6 text-[#F4FFDC]`}
                >
                  iot
                </a>
              </li>
              <li className="cursor-pointer relative  min-w-[150px] max-w-[300px]  py-2 px-3 text-white hover:text-red-500 focus:text-red-500 hover:underline rounded bg-transparent  hover:font-bold ipadMin:p-0 ">
                {" "}
                <a
                  target="_blank"
                  href="http://nischai.com/"
                  className={`cursor-pointer hover:scale-[1.1] hover:font-bold ${
                    isActive === "nischai" && "underline"
                  } largeScreen:px-6 text-[#F4FFDC]`}
                >
                  nischai
                </a>
              </li>
              <li className="cursor-pointer relative  min-w-[150px] max-w-[300px]  py-2 px-3 text-white hover:text-red-500 focus:text-red-500 hover:underline rounded bg-transparent  hover:font-bold ipadMin:p-0 ">
                {" "}
                <a
                  target="_blank"
                  href="http://localsetu.com/"
                  className={`cursor-pointer hover:scale-[1.1] hover:font-bold ${
                    isActive === "localsetu" && "underline"
                  } largeScreen:px-6 text-[#F4FFDC]`}
                >
                  Localsetu
                </a>
              </li>
              <li
                className="cursor-pointer min-w-[150px] max-w-[300px]  py-2 px-3 text-white  rounded bg-transparent focus:text-red-500 hover:text-red-500 hover:underline hover:font-bold"
                aria-current="page"
                onClick={() => navigate("/contact-theja.in")}
              >
                contact us
              </li>
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};
