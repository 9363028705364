import React from "react";
import { textContent, titleContent } from "../../Style";
import { ReadMore } from "../../buttons/Buttons";

export const Ideas = () => {
  return (
    <div className=" w-full p-4 px-12
    h-fit flex flex-row text-green-900 flex-wrap items-center mobile:gap-5 ipadMax:gap-10 ipadMin:gap-10">
     
   <div className="cssanimation sequence fadeInBottom w-[65%] mobile:w-full ipadMax:w-full  ipadMin:w-full flex flex-col items-center mobile:items-center ipadMax:text-center ipadMin:text-center mobile:text-center text-left gap-5 laptop:px-10">
   <div className={`${titleContent}   w-full font-[200] text `}>
      Turning  <span className="font-semibold ">Ideas</span> into  <span className=" font-semibold">Real</span>
    </div>
    <div className={`${textContent} w-full tracking-wider  text`}>
        Discover
        Innovative IoT Hub At our IoT innovation hub, we are a collective of
        driven individuals fueled by our relentless dedication to bringing
        ideas to life. With a diverse tapestry of backgrounds and a wealth of
        expertise, we converge to engineer transformative solutions for our
        esteemed clientele. <br />
        <br />
       Collaboration is the cornerstone
        of our ethos. Our team flourishes in an environment where diverse
        perspectives intersect, fostering a culture of limitless innovation.
        By harnessing our combined talents, we cultivate groundbreaking
        outcomes that redefine possibilities and propel our clients towards
        unparalleled achievements.
      </div>
     <a target="_blank"href="https://iot.theja.in/#/read-more"> <ReadMore /></a>
   </div>
   <div className="flex flex-row flex-wrap mobile:w-full ipadMax:w-full ipadMin:w-full w-[35%]  h-fit justify-center mobile:justify-center ipadMin:justify-center ipadMax:justify-center">
  <img className="hover:scale-[1.01] w-[240px] h-[240px]" src={require('../../assets/ideas_in.png')} alt="" />
  <img className="hover:scale-[1.01] w-[200px] h-[200px]" src={require('../../assets/ideas_2.png')} alt="" />
  <img className="hover:scale-[1.01] w-[150px] h-[150px]" src={require('../../assets/ideas_3.png')} alt="" />
   </div>
  </div>

  );
};
