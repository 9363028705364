import React, { useEffect } from "react";
import { Header } from "../header/Header";
import WhoWeAre from "./Who_We_Are";
import AboutServices from "./AboutServices";
import TakeALook from "./TakeALook";
import { Footer } from "../Footer";
import OurPeople from "./OurPeople";

export default function AboutUs() {
  useEffect(()=>{

    if (window.location.href==="https://theja.in/#/about-theja.in") {
      localStorage.setItem('active','about')
    }
  },[window.location.href])

  useEffect(()=>{
    if (localStorage.getItem('idScroll')) {
     document.getElementById(localStorage.getItem('idScroll')).scrollIntoView({ behavior: "instant" })
    }
      localStorage.removeItem('idScroll');
  },[])
  return (
    <div className="fixed inset-0 w-screen h-screen flex flex-col overflow-hidden">
      <div>
        <Header />
      </div>
      <div className="w-full h-full tracking-wider z-[-1] overflow-y-auto h-full overflow-x-hidden">
       <div> <WhoWeAre /></div>
      <div>  <AboutServices /></div>
       <div> <TakeALook /></div>
      <div id="careers">
      <OurPeople />
      </div>
      <div>  <Footer /></div>
      </div>
    </div>
  );
}
