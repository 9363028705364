import React, { useEffect, useState } from "react";
import { Header } from "./header/Header";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import ReCAPTCHA from "react-google-recaptcha";
import { Footer } from "./Footer";
import { useNavigate } from "react-router-dom";

export default function ContactUs() {
  const [verifiedCaptcha, setVerifiedCaptcha] = useState(false);
  const [checkBox, setCheckbox] = useState(false);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");


  useEffect(()=>{

    if (window.location.href==="https://theja.in/#/contact-theja.in") {
      localStorage.setItem('active','contactus')
    }
  })

  const navigate=useNavigate()

  const termsAndConditions = () => {
    localStorage.setItem("active", "terms");
    navigate('/terms-conditions')
  };
  return (
    <div className="w-full h-full fixed flex flex-col">
      <div className="w-full h-fit">
        <Header />
      </div>
     
      <div className="w-full  tracking-wider h-full overflow-y-auto h-full overflow-x-hidden">
    <div
        className={`w-full overflow-hidden h-fit py-4 flex flex-row flex-wrap items-center justify-center px-40 pr-20 mobile:px-0 mobile:pr-0 ipadMin:px-0 ipadMin:pr-0 bg-[linear-gradient(108.71deg,_#08740A_10.43%,_#138315_45.11%,_#2DA92F_91.43%)]`}
      >
     
        <div className="w-[90%] tracking-wider cssanimation sequence fadeInBottom bg-[#0F0F0F2B] rounded-[40px]  h-fit py-4 flex flex-row justify-center ipadMin:flex-col mobile:flex-col  gap-4 items-center">
          
          <div className="w-[250px]  ipadMin:w-[80%]  mobile:w-[80%] ml-[-120px]  mobile:ml-0 ipadMin:ml-0  h-fit largeScreen:relative flex flex-col items-center text-center gap-2 rounded-2xl bg-[#05FF872E] p-4">
            <strong>GET IN TOUCH</strong>
            <span className="text-gray-300 font-[400] capitalize">
              Feel free to Connect with us during office hours.
            </span>

            <div className="w-fit h-fit p-0 px-1 rounded-lg bg-white">
              <LocalPhoneIcon color="primary" />
            </div>
            <span className="text-white font-[400]">9000078933</span>

            <span>Mail me</span>
            <span onClick={()=>window.open("mailto:support@example.com?subject=SendMail&body=Description")} className="cursor-pointer hover:underline text-white font-[400]">hello@theja.in</span>
          </div>
          <div className="w-full h-fit">
            <form
              action="https://formsubmit.co/hello@theja.in"
              method="POST"
              className=" h-fit w-full flex flex-col  items-start mobile:items-center ipadMin:items-center text-white  gap-5 mobile:pr-0 ipadMin:pr-0"
            >
              <input
                type="text"
                placeholder="Enter Your Name "
                name="name"
                value={name}
                required
                onChange={(e) => setName(e.target.value)}
                className="bg-transparent placeholder:text-gray-300 hover:scale-[1.02] w-[80%] outline-none border p-2 rounded-xl"
              />
              <input
                type="email"
                placeholder="Enter A Valid Email Address"
                value={email}
                name="email"
                required
                onChange={(e) => setEmail(e.target.value)}
                className="bg-transparent w-[80%] placeholder:text-gray-300 hover:scale-[1.02] outline-none border p-2 rounded-xl"
              />
              <textarea
                type="text"
                name="message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                placeholder="Write Your Message"
                className="bg-transparent w-[80%] placeholder:text-gray-300 hover:scale-[1.02] outline-none min-h-[100px] border p-2 rounded-xl"
              />
              {/* you can add cc mails below input field as values */}
              <input type="hidden" name="_cc" value="" />
              <input type="hidden" name="_captcha" value="false" />

              <input type="hidden" name="_template" value="box" />
              <input
                type="hidden"
                name="_subject"
                value="New message from Theja Technologies Website"
              />
              <input
                type="hidden"
                name="_next"
                value="https://theja.in/#/form-submitted"
              />

              <label >
                <input type="checkbox" id="acceptTerms" required  checked={checkBox} onChange={()=>setCheckbox(!checkBox)} />{" "}
                <label htmlFor="acceptTerms">
                  I Accept The <span onClick={termsAndConditions} className="underline hover:text-[#FBE204] cursor-pointer">Terms and Conditions</span>
                </label>
              </label>

              <ReCAPTCHA
                sitekey="6Ldm9sIpAAAAANMwvjvq0vG81IXs_UiyaT9vcNjO"
                onChange={() => setVerifiedCaptcha(!verifiedCaptcha)}
              />

              <input
                type="submit"
                disabled={!verifiedCaptcha}
                value="SUBMIT"
                className={`bg-[#FFFFFDC4] text-[#000] p-2 px-6 rounded-lg ${
                  verifiedCaptcha && "cursor-pointer hover:bg-gray-200"
                } `}
              />
            </form>
          </div>
        </div>
        <div className="relative w-full"> 
         
       <div className="relative mobile:hidden left-0 top-0 largeScreen:top-40 w-full largeScreen:mt-5 flex flex-col h-full justify-center items-center">
       <div className="absolute right-[-170px] top-[-350px] border-[4px] border-[#FBE204] border-opacity-[40%]  rounded-full w-[180px] h-[180px]"></div>
         <div className="absolute left-[-240px] top-[-100px] border-[4px] border-[#FBE204] border-opacity-[40%]  rounded-full w-[180px] h-[180px]"></div>
       </div></div>
      
      </div>
      <Footer />
    </div>
    </div>
  );
}
