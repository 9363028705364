import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import SouthIcon from '@mui/icons-material/South';
import {useNavigate} from 'react-router-dom'
export const WhatWeDoButton = () => {
  const navigate=useNavigate();

  return (
    <div  onClick={()=>navigate("/What-we-do")} className="w-fit p-4 cursor-pointer hover:scale-[1.1]  px-8 rounded-[60px] text-black font-[400] flex flex-row items-center gap-5 uppercase bg-[#FBE204]">
      <strong className="text-[1.15rem] font-[500] text-[#1D4111]">what we do</strong>{" "}
      <span className="bg-[#35751F]  rounded-full  p-2 px-[8px] flex items-center justify-center w-fit h-fit">
        <img className="w-4" src={require('../assets/arrow-icon.png')} alt="" />
      </span>
    </div>
  );
};

export const ViewOurWorks = () => {
  const navigate=useNavigate();
  return (
    <div  onClick={()=>navigate("/our-works")} className="cursor-pointer w-fit p-3 px-4 hover:scale-[1.1] border border-[#FBE204] rounded-[60px] text-white font-[400] flex flex-row items-center gap-5 uppercase">
      <strong  className="text-[1.15rem] font-[500] ">view our works</strong>{" "}
      <span className=" bg-[#FBE204] rounded-full  p-2 px-[8px] flex items-center justify-center w-fit h-fit">
        <img className="w-4" src={require('../assets/green-arrow.png')} alt="" />
      </span>
    </div>
  );
};
export const BottomScroll = () => {
  return (
    <div className="cursor-pointer flex justify-end rounded-full w-fit h-fit bg-[#FBE204] p-3">
      <span className="bg-[#35751F]  rounded-full  p-2 px-[8px] flex items-center justify-center w-fit h-fit">
        <img className="w-4" src={require('../assets/arrow-icon.png')} alt="" />
      </span>
    </div>
  );
};
export const ReadMore = () => {

  return (
    <div className=" w-fit p-4 px-8 cursor-pointer hover:scale-[1.1] rounded-[60px] text-black font-[400] flex flex-row items-center gap-5 uppercase bg-[#FBE204]">
      <strong className="text-[1.15rem] font-[500] text-[#1D4111]">Read more</strong>{" "}
      <span className="bg-[#35751F]  rounded-full  p-2 px-[8px] flex items-center justify-center w-fit h-fit">
        <img className="w-4" src={require('../assets/arrow-icon.png')} alt="" />
      </span>
    </div>
  );
};
export const ContactUs = () => {
  return (
    <div className="cursor-pointer w-fit hover:scale-[1.1] p-4 px-8 rounded-[60px] text-black font-[400] flex flex-row items-center gap-5 uppercase bg-[#FBE204]">
      <strong className="text-[1.15rem] font-[500]">Contact Us</strong>{" "}
      <span className="bg-[#35751F] rounded-full  p-1 flex items-center justify-center w-fit h-fit">
        <ArrowRightAltIcon style={{ fontSize: "20px", color: "#FBE204" }} />
      </span>
    </div>
  );
};
export const JoinUs = () => {
  const navigate=useNavigate();
  return (
    <div onClick={()=>navigate("/careers")} className="w-fit p-4 hover:scale-[1.1] cursor-pointer px-8 rounded-[60px] text-black font-[400] flex flex-row items-center gap-5 uppercase bg-[#FBE204]">
      <strong className="text-[1.15rem] font-[500] text-[#1D4111]">Join Us</strong>{" "}
      <span className="bg-[#35751F]  rounded-full  p-2 px-[8px] flex items-center justify-center w-fit h-fit">
        <img className="w-4" src={require('../assets/arrow-icon.png')} alt="" />
      </span>
    </div>
  );
};

export const RightArrowIcon=()=>{
  return(
    <span className="bg-[#35751F]  rounded-full  p-2 px-[8px] flex items-center justify-center w-fit h-fit">
    <img className="w-4" src={require('../assets/arrow-icon.png')} alt="" />
  </span>
  )
}