import React from "react";
import { card_bg_color, textContent, titleContent } from "../../Style";

export default function ChooseSector() {
  return (
    <div className="w-full h-fit p-10 py-6 flex flex-row flex-wrap items-center justify-center">
      <div className="flex flex-col gap-6 mobile:items-center ipadMin:items-center  w-full">
        <div className={`text ${titleContent} font-[200] text-center`}>
          Choose <span className="text font-semibold">Your Sector. </span> Discover{" "}
          <span className="text font-semibold">Our Impact</span>
        </div>
        
        <div className="relative mobile:hidden right-[-100px]   largeScreen:top-40 w-full largeScreen:mt-5 flex flex-col h-full justify-center items-center">
          <div className="absolute  right-0 border-2 border-[#D1F2C2] mt-[150px] border-opacity-[40%]  rounded-full w-[150px] h-[150px]"></div>

          <div className="absolute right-0  mt-[460px] border-8 border-[#50F1043D] border-opacity-[40%]  rounded-full w-[150px] h-[150px]">
            <div className="w-full h-full  border-8 border-[#50F1043D] border-opacity-[40%]  rounded-full w-[150px] h-[150px]"></div>
          </div>
        </div>

        <div className="relative mobile:hidden right-[-72px] largeScreen:top-40 w-full largeScreen:mt-5 flex flex-col h-full justify-center items-center">
          <div className="absolute left-[-170px]  mt-60 border-8 border-[#50F1043D] border-opacity-[40%]  rounded-full w-[150px] h-[150px]">
            <div className="w-full h-full  border-8 border-[#50F1043D] border-opacity-[40%]  rounded-full w-[200px] h-[200px]"></div>
          </div>
        </div>

        <div className="flex flex-row flex-wrap justify-center gap-2 w-full">
          <div className="w-[48%] h-fit ipadMin:w-[70%] mobile:w-full flex flex-col gap-5 laptop:px-8  largeScreen:px-8 ">
            <div
              className={`w-full max-w-[600px] h-[150px] mobile:h-fit  hover:bg-green-100 p-4 rounded-[42.56px]  flex flex-row items-start gap-4`}
            >
              <div className="w-11 scale-[1.3] mobile:w-12 h-10 rounded-full flex justify-center items-center bg-[#086706] text-white">
                01
              </div>
              <div className="w-full text-gray-500">
                <span className={`${textContent} font-semibold text-[#086706]`}>IOT</span> <br />
                Using IOT in farming aims to boost productivity , use resources
                more accurately , and encourage sustainability
                <br />
                <a  href="https://iot.theja.in/" target="_blank" rel="noopener noreferrer" className="underline font-bold hover:text-black ">iot.theja.in</a>
              </div>
            </div>
            <div
              className={`w-full max-w-[600px]  h-[150px] mobile:h-fit hover:bg-green-100 p-4 rounded-[42.56px]  flex flex-row items-start gap-4`}
            >
              <div className="w-11 scale-[1.3] mobile:w-12 h-10 rounded-full flex justify-center items-center bg-[#9CFB70] text-white">
                02
              </div>
              <div className="w-full text-gray-500">
              <span className={`${textContent} font-semibold text-[#086706]`}>Local Setu</span> <br />
                An inclusive online marketplace where sellers connect with
                buyers, creating a platform for seamless transactions <br />
                <a href="http://localsetu.com/"  target="_blank" rel="noopener noreferrer" className="underline font-bold hover:text-black ">localsetu.com</a>

              </div>
            </div>
          </div>

          <div className="w-[48%] ipadMin:w-[70%] mobile:w-full flex flex-col gap-5 laptop:px-8  largeScreen:px-8">
            <div
              className={`w-full max-w-[600px]  h-[150px] max:h-fit mobile:h-fit hover:bg-green-100 p-4  rounded-[42.56px]  flex flex-row items-start gap-4`}
            >
              <div className="w-11 scale-[1.3] mobile:w-12 h-10 rounded-full flex justify-center items-center bg-[#5BD024] text-white">
                03
              </div>
              <div className="w-full text-gray-500">
              <span className={`${textContent} font-semibold text-[#086706]`}>Nischai</span> <br />
                Empowering through quality educ-ation that not only imparts
                know-ledge but also focus on personal empowerment{" "}
                <br />
                <a href="http://nischai.com/" target="_blank" rel="noopener noreferrer" className="underline font-bold hover:text-black ">nischai.com</a>

              </div>
            </div>

            <div
              className={`w-full max-w-[600px]  h-[150px] mobile:h-fit hover:bg-green-100 p-4 rounded-[42.56px]  flex flex-row items-start gap-4`}
            >
              <div className="w-11 scale-[1.3] mobile:w-12 h-10 rounded-full flex justify-center items-center bg-[#3FB608] text-white">
                04
              </div>
              <div className="w-full text-gray-500">
              <span className={`${textContent} font-semibold text-[#086706]`}>Consulting Services</span>{" "}
                <br />
                We Encompass a wide range of professional advisory offerings
                aimed at helping individuals, businesses, achive goals and
                improve performance{" "}
                <br />
                <a href="" target="_blank" rel="noopener noreferrer" className="underline font-bold hover:text-black "></a>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
