import React from 'react'
import { Header } from '../header/Header'
import { Footer } from '../Footer'
import What_we_intro from './What_we_intro'
import ValuesDrivenEnterprise from './ValuesDrivenEnterprise'
import ChooseSector from './ChooseSector'
import Services from './Services'
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { useNavigate } from 'react-router-dom'
import { HomeNavigator } from '../../util/HomeNavigator'

export default function What_We_Do() {
  const navigate=useNavigate();
  const homeHandler = () => {
    navigate("/");
    localStorage.setItem("active", "home");
  };
  return (
    <div className="fixed inset-0 w-screen h-screen flex flex-col overflow-hidden">
      <div>
        <Header />
      </div>
      <HomeNavigator id="whatWeDo" />
      <div className="w-full tracking-wider h-full overflow-y-auto h-full overflow-x-hidden">
      
       <What_we_intro />
       <ValuesDrivenEnterprise />
       <ChooseSector />
       <Services />
        <Footer />
      </div>
    </div>
  )
}
