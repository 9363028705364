import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import {  useNavigate } from 'react-router-dom';

export const HomeNavigator=({id,screen})=>{
    const navigate=useNavigate();

   
    const homeHandler = () => {
      localStorage.setItem('idScroll',id)
      if (screen==="about") {
        navigate("/about-theja.in")
        localStorage.setItem("active", "about");
      }
      else{
        navigate(`/`);
        localStorage.setItem("active", "home");
      }
      
    };
    return <div className={`w-[40px] rounded-full h-[40px]  cursor-pointer fixed top-[12vh] grid place-items-center left-3 ${"bg-slate-100"}`} onClick={homeHandler}>
    <NavigateBeforeIcon className="hover:scale-[1.3]" />
   </div>
}