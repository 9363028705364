import React from 'react'
import {useNavigate} from 'react-router-dom'

export default function SuccessMessageForm() {
    const navigate=useNavigate();

  return (
    <div className='w-screen h-screen grid place-items-center'>
        <div className={`flex flex-col items-center bg-gray-200 p-6 rounded-md`}>
            <h1 className='py-2 text-[2rem]'>Congratulations! Successfully recieved your response</h1>
            <h1 className='py-2 text-[1.6rem]'>We will get back to you soon!</h1>
            <div>Go to <a target="_blank" onClick={()=>navigate("/")} className='text-blue-600 cursor-pointer hover:underline'>Home</a></div>
        </div>
    </div>
  )
}
