import React, { useEffect } from "react";
import { Home } from "./Home";
import { Ideas } from "./Ideas";
import OurService from "./OurService";
import { OurTeam } from "./OurTeam";
import { ProjectsOfCompany } from "./ProjectsOfCompany";
import { OurCulture } from "./OurCulture";
import { Footer } from "../Footer";
import { Header } from "../header/Header";

export default function HomeScreen() {
  useEffect(()=>{

    if (window.location.href==="https://theja.in/#/"||window.location.href==="https://theja.in/") {
      localStorage.setItem('active','home')
    }
  })

 useEffect(()=>{
   if (localStorage.getItem('idScroll')) {
    document.getElementById(localStorage.getItem('idScroll')).scrollIntoView({ behavior: "instant" })
   }
     localStorage.removeItem('idScroll');
 },[])
  return (
    <div className="fixed inset-0 w-screen h-screen flex flex-col overflow-hidden">
      <div>
        <Header />
      </div>
      <div className=" w-full h-full tracking-wider overflow-y-auto h-full overflow-x-hidden ">
        <div id="whatWeDo">
          <Home />
        </div>
        <div>
          {" "}
          <Ideas />
        </div>
        <div>
          <OurService />
        </div>
        <div id="meetTeam">
          <OurTeam />
        </div>
        <div>
          <ProjectsOfCompany />
        </div>
        <div id="careers">
          <OurCulture />
        </div>
        <div>
          {" "}
          <Footer />
        </div>{" "}
      </div>
    </div>
  );
}
